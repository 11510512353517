<template>
    <main>
        <div class="page-conteudo">
            <div class="container">
                
                <div class="image">
                    <div class="text-image">
                         <h2>MASSAGEM TÂNTRICA<br/> PARA MULHERES</h2>
                    </div>
                </div>
               
                <div class="conteudo">
                    <div class="conteudo-corrente">
                    <h4>MASSAGEM TÂNTRICA PARA MULHERES <br/>
                    Desenvolvimento Individual na Massagem Tântrica</h4>
                    <h4>A MASSAGEM TÂNTRICA</h4>
                    <p>“Podemos considerar uma das ferramentas presentes na terapêutica tântrica como um portal para conexão com o corpo e sensações. A Massagem Tântrica para homens é uma experiência de autoconhecimento ampla e expansão sensorial que pretende acompanhar um desenvolvimento de totalidade”</p>
                    <p>“Viva essa experiência. Aprecie esse estilo de vida. Seja bem-vindo ao espaço Danrê”</p>
                    <h4>FILOSOFIA DANRÊ</h4>
                    <img src="../assets/FILOSOFIA-DANRE.png" alt="Filosofia Danrê">
                    <h4>METODOLOGIA DANRÊ</h4>
                    <p class="color-green"><strong>o que vai acontecer na massagem?</strong></p>
                    <p>Desenvolvemos uma metodologia divida em quatro partes, onde:</p>
                    <p><strong>1° etapa: Talk – Diálogo</strong></p>
                    <p>Aqui o(a) nosso(a) terapeuta conduzirá uma breve conversa para entender as suas demandas.
Como estará em um ambiente seguro e com total discrição você poderá se sentir à vontade para se expressar verbalmente, falando sobre seu corpo, suas carências, algum distúrbio (se houver), alguma curiosidade sobre o tantra, tirar qualquer dúvida ou, até mesmo, não falar nada. 
Esse tipo de abordagem é a conduta ideal para que possamos te proporcionar uma terapia personalizada.
Esse é o seu momento, você decide como quer iniciar a sua terapia e qual técnica quer utilizar.
A (o) terapeuta irá te passar técnicas de respiração, movimentação, conexão para que assim, consigamos dar mais eficácia à terapia.
</p>
                    <p><strong>2º etapa: Deep Release + SensitiveTouch |Soltura Profunda +Toque Sensorial</strong></p>
                    <p>Iniciaremos com você em decúbito ventral e com um toque vigoroso que simboliza nosso contato com a terra, a fim de despertar as nossas memórias corporais, estimularemos a resiliência muscular do corpo. Associaremos esta etapa aos elementos Terra e Água. Presença e firmeza, fluidez e leveza. Com manobras alternadas de toques firmes e deslizamentos daremos início a sessão e a imersão na experiência Danrê.</p>
                    <p>A experiência tantra não tem um objetivo definido, porém, o “foco” desta etapaé despertar no corpo físico a bioletricidade através do toque leve, sutil sobre a pele, que representa o elemento ar. Vale ressaltar que a circulação dessa eletricidade é a grande responsável pelo equilíbrio físico, mental e emocional do nosso organismo. Muitas doenças são causadas pela falta desse suprimento elétrico no corpo. Vivemos em um grande campo magnético.Os estímulos elétricos causados pelos toques, aos poucos, vão ganhando potência e logo tornam-se espasmos musculares – são movimentos musculares que surgem em forma de ondas intensas convertidas inconscientemente em prazer. A potencialização enegética dessas ondas dá origem a uma experiência orgástica. Acontece então aquilo que chamamos de “Orgasmo seco”, ou “Ondas orgásticas”.</p>
                    <p><strong>3° etapa: Yoni Massagem</strong></p>
                    <p>Yoni é uma palavra do Sânscrito (योिन) que significa “passagem divina”, “lugar de nascimento”, “fonte de vida”, “templo sagrado” e ainda o órgão sexual feminino. É considerado igualmente um símbolo de Shakti e de outras deusas de natureza similar.</p>
                    <p>Na Yoni Massagem, o trabalho é focado, superdimensionado e intensificado na Vagina (Yoni) . É importante frisar que estas manipulações genitais não são movimentos masturbatórios, são técnicas que estimulam a vulva e a vagina que foram profundamente estudados e pesquisados, e são especificamente aplicados com o objetivo de aumentar o aspecto sensorial da região genital, proporcionando um tipo de prazer e estados alterados de percepção e consciência que nunca seriam experimentados com manipulações usuais masturbatórias ou sexuais. Na Yoni Massagem, geralmente as mulheres experimentam orgasmos múltiplos, sem declínio do prazer e uma expansão da energia em direção ao 7º Chakra – Saashara, um centro de energia localizado no topo da cabeça, vinculado ao sagrado, ao aspecto divino que existe dentro de nós.São utilizados vários tipos de toques, visando levar maior oxigenação e irrigação sanguínea para toda a área pélvica, ativando pontos reflexológicos e proporcionando inúmeras sensações que, por vezes, as mulheres nunca sentiram durante toda a sua vida sexual.</p>
                    <p><strong>4° etapa: G-Spot Massagem</strong></p>
                    <p>Nesta técnica Danrêoferecemos a possibilidade da mulher explorar todo o potencial que a área G, também conhecido como Ponto G, pode oferecer em termos de expansão sensorial e um nível máximo de prazer. Nas Mulheres o estímulo é produzido após uma sucessão de manobras específicas na região vagina (parte interna) e vulva (parte externa que compreende omonte de vênus, o clitóris e os grandes e pequenos lábios), em contato direto com essa região.</p>
                    <p>Nossa experiência demonstra que o trabalho possibilita um aumento significativo na intensificação do prazer primeiramente por conectar e devolver a consciência da mulher a esta região que dificilmente é estimulado ou tocada em relações ou masturbações.</p>
                    <p>Sim, existem mulheres que não conhecem a sua anatomia e, tão pouco sabem como se satisfazer ou ensinar ao parceiro como satisfazê-la. As sessões são uma ótima oportunidade para se conhecer, se ver de uma forma mais real, se conectar com o seu eu interior e, principalmente, se amar.</p>
                    <p class="color-green"><strong>O seu corpo fala com você! Você sabia?</strong></p>
                    <p >Nosso corpo se comunica a todo momento conosco. Com frequência essa pode ser uma difícil relação pois há uma enorme influência da cultura, família, caminhos religiosos e sistema educacional. Vivemos sob um modelo patriarcal, apoiado fortemente no protagonismo da mente e do homem sobre as supostas "forças antagônicas". A mente é mais importante que o corpo, assim como o homem é mais importante que a natureza. Assim, em nosso movimento histórico-civilizatório, reprimimos e suprimimos o feminino, inclusive a confiança nos sentidos, no instinto humano, na intuição. </p>
                    <p>Assim como a natureza é vista como sendo caótica e perigosa se não for controlada, tal como são vistas as mulheres, o modelo patriarcal traduz os sentimentos como sinal de fraqueza e vulnerabilidades, quase uma distração. E, especialmente no Ocidente, com o predomínio da tradição judaico-cristã, predomina uma visão espiritual que trata o corpo como fonte de pecados e um inimigo à elevação do espírito. 
Por fim, incorporamos uma visão de mundo baseada no saber científico, que despreza aquilo que não é observável ou mensurável. Perdemos assim o aspecto sagrado do corpo e substituímos pelo entendimento da "máquina de bom funcionamento, cujas partes podem ser substituídas, mas que por fim se desgasta e acaba". 
</p>
                    <p>Todas as experiências contam, e aquelas mais significativas certamente produziram assinaturas de energia em nosso corpo, justamente pela nossa incapacidade em dar vazão a suas expressões e sentimentos decorrentes. Freud diz que "no inconsciente nada termina, nada é passado ou esquecido". A psicanálise afirma que as emoções não-resolvidas irão penetrar no comportamento, manifestando-se de diversas formas, como por exemplo nos sonhos, pensamentos, brincadeiras, sintomas físicos e padrões de relacionamento. </p>
                    <p>Enquanto nos mantivermos afastados da consciência corporal, não seremos capazes de lidar com as couraças musculares decorrentes da nossa necessidade de nos defender das emoções, memórias difíceis, traumas e estresses. Predominarão assim as demandas da família, sociedade e religiões sobre as nossas reais demandas por autoaceitação, autoconhecimento e desenvolvimento pessoal.</p>
                    <p>Para o Tantra a busca consiste em viver uma experiência de transcendência, tendo o corpo humano como o principal "veículo" para viabilizar esse novo estado. Mas transcendência do que, exatamente? O verbo transcender significa superar algo, ir além de algum limite. Uma boa forma de interpretar esse estado de transcendência tem a ver com a ideia dos ciclos de existência. </p>
                    <p>Enquanto na tradição cartesiana ocidental a passagem do tempo é vista como uma experiência linear que liga o passado ao futuro, a abordagem tântrica a visualiza como uma interessante criação e recriação de ciclos. A existência e o universo para o Tantra parecem pulsar em ciclos de expansão, contração e relaxamento. Especialmente, a cada ciclo o ser humano proporciona a si mesmo oportunidades de aprendizado e expansão de consciência.</p>
                    <p>O Tantra é o caminho da celebração da existência, de aceitação da nossa condição de seres em constante processo de evolução e do compromisso inabalável com o autoconhecimento. O Tantra é a realização aqui e agora de todo o potencial humano. Para o Tantra, a experiência de vida se dá no estado de relaxamento, não na luta.</p>
                    <p>Na luta, o movimento energético cessa e o ser humano se enrijece. Isso vai contra a vida. Vida para o Tantra é movimento, é fluxo constante, assim como as águas se movimentam em direção ao oceano, em determinados momentos em fluxo mais caótico, em outros mais contemplativo e sereno. </p>
                    <h4>QUAIS OS BENEFÍCIOS?</h4>
                        <div class="beneficios">
                            <div class="col">
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">	Tratamento de distúrbios sexuais (ejaculação precoce, ejaculação tardia e anorgasmia)</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">	Tratamento de distúrbios psicossomáticos</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">	Trabalho e tratamento de traumas</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Toque consciente</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Melhora dos relacionamentos afetivos</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Melhora a qualidades dos orgasmos</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Melhora a qualidade do sono</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Melhora a performance sexual</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Liberação dos bloqueios energéticos sexuais</p>
                            </div>
                            <div class="col">
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Despertamento espiritual</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Desperta regiões sensoriais adormecidas</p>  
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Causa sensação de bem-estar profundo</p>                          
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Autoconfiança</p>    
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Aumento da libido e orgasmos múltiplos</p>    
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Alívio de estresse</p>    
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Alívio de dores</p>    
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Ajuda na melhora de bloqueios emocionais</p>    
                        
                            </div>
                        </div>
                    </div>

            </div>
            <div class="flex">
                        <div class="col">
                            <h4>INVESTIMENTO</h4>
                            <p>R$ 250,00 – Sessão única Sessão única (dinheiro, pix credito ou débito)</p>

                        </div>
                        <div class="col">
        <h4>DÚVIDAS FREQUENTES</h4>
                <vsa-list>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Qual a Segurança com a Higiene e Saúde?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Todos os terapeutas cadastrados no Paraíso Tantra seguem protocolos de biossegurança, saúde e higiene rigorosos para os atendimentos. A utilização de luvas nitrílicas ou látex para os processos de massagens íntimas e internas fazem parte deste protocolo. Caso você tenha hipersensibilidade ao látex temos um outro material disponível. Como padrão em nossas unidades todos os nossos lençóis são descartáveis após o atendimento. A limpeza e esterilização de materiais para o procedimento é feito conforme as recomendações da ANVISA (agência nacional de vigilância sanitária) aos quais somos certificados.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Quais são os Produtos Utilizados?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Materiais de origem vegetal para o corpo e genitais. Óleo de semente de Uva ou Óleo de Coco, famosos por terem propriedades medicinais e não representarem qualquer dano à saúde.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Como o terapeuta fica vestido?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Para a massagem tântrica não há necessidade de o terapeuta despir-se. O terapeuta facilita o seu processo na condução e movimentação da energia sexual. Sendo um condutor e facilitador do seu aprendizado o terapeuta utiliza roupas leves, confortáveis, que facilitem sua movimentação durante o processo da massagem.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Como eu fico vestido?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>O cliente fica com suas roupas íntimas coberto por uma toalha,  a única parte que fica exposta é a parte que vai ser tratada.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Existe Masturbação?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Não, você não será masturbado(a). Os processos de massagem lingam e yoni massagem são cientificamente estudados e exigem um conhecimento técnico específico para serem realizados, são manobras e movimentos alternados que possibilitam um conhecimento amplo da região. Todos os nossos profissionais estão capacitados para realizá-los. Então, fique tranquilo (a). A masturbação é um procedimento automático com movimentos repetitivos e pobres que fazem alcançar um “prazer” imediato através da pressão e manipulação impossibilitando totalmente o conhecimento corporal intenso e o orgasmo terapêutico.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Como posso “ejacular” sem ter sexo, penetração ou interação?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Esse é o ponto. Se você é uma pessoa que não imagina como poderia chegar a um êxtase total sem sexo, interação ou qualquer tipo de contato direto com uma outra pessoa, você realmente precisa conhecer o Tantra e a sua capacidade orgástica. A ejaculação é um processo distinto do orgasmo. (leia mais em nosso blog)</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Posso tocar o terapeuta?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>O Toque é um dos sentidos mais apurados dentro da nossa cultura e que praticamos involuntariamente. Vamos as compras ou ao supermercado e olhamos um produto, uma roupa, na prateleira. Pedimos ao vendedor: -“Posso ver?” e ele coloca o produto em cima de suas mãos. Se não pudermos tocar, virar, sentir a textura do tecido nós realmente não o enxergamos. Estamos o tempo todo utilizando os nossos sentidos, mesmo sem ter os percebidos realmente. O toque como um formato de sentir-se seguro na sessão, apoiar-se no outro é bem-vindo, o que não pode acontecer é uma vulgarização através do toque acreditando ser um processo de troca ou mais uma distração para ir para fora. A experiência é meditativa e a proposta é a conexão consigo. Incentivamos o auto toque para conexão!</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Existe algum contato corpo a corpo?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Não. Existem algumas informações e retratações infiéis a massagem tântrica espalhadas por aí. Em algumas delas é visto uma pessoa esfregando o corpo na outra, deslizando com óleos, gel, creme. Nada disso é Tantra, aliás isso não irá lhe trazer nenhum beneficio de conhecimento corporal. Nossos terapeutas estão inteiramente conectados com a proposta de trazer maior expansão e conexão orgástica além de trabalhar o desenvolvimento para auxílio as disfunções sexuais.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Posso ser atendido por um terapeuta do mesmo sexo?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Sim. Os terapeutas são condutores da capacidade orgástica e energia sexual. Se uma mulher receber a sessão de outra mulher ela não se torna homossexual por isso. Inclusive algumas mulheres sentem-se mais confortáveis com uma terapeuta, logo a questão da polaridade (homem/mulher) pode ser descartada como regra. Você pode fazer a sessão com quem se sentir mais confortável.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Qual o tempo de duração da sessão?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Em média 60 minutos (1h). Recomendamos que deixe um espaço de tempo de 1h e meia para realizar a sessão. Não se preocupe com o seu compromisso posterior durante o processo. É o seu momento, nada pode te atrapalhar.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Existe sexo durante este processo?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Não. Sexo de qualquer espécie, complementos sexuais ou afins estão completamente descartados das nossas sessões.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>É possível realizar a sessão em menor tempo?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Não, a sessão de Massagem Tântrica é detalhada, expansiva e é necessário o período estipulado.</p>
                        </vsa-content>
                    </vsa-item>
                                     <vsa-item>
                        <vsa-heading>
                         <h3>Posso escolher a terapeuta?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Claro. Fique a vontade, todos os nossos terapeutas estão capacitados para o atendimento.</p>
                        </vsa-content>
                    </vsa-item>
                </vsa-list>
                        </div>
                <div class="col">
                    <h4>CONTRA INDICAÇÕES NA MASSAGEM!</h4>
                    <p>Todas as terapias possuem suas indicações e contraindicações. </p>
                    <p>Nossas massagens são contraindicadas para indivíduos com:</p>
                    <p>Contusões, pele inflamada, ferimentos, tumores, hérnia abdominal e áreas com fraturas recentes;</p>
                    <p>Se você estiver com alguma condição dérmica infecciosas, rash ou feridas abertas;</p>
                    <p>Após cirurgias recentes;</p>
                    <p>Logo após sessões de quimioterapia ou radioterapia, a menos que seja recomendado por seu médico;</p>
                    <p>Propensão a coágulos sanguíneos. Existe o risco dos coágulos se soltarem. Se você tem algum tipo de doença cardíaca verifique com seu médico se há alguma restrição para este tipo de massagem;</p>
                    <p>Mulheres grávidas devem sempre consultar o médico antes de receber massagem.</p>
                    <p>Asma aguda, cistite aguda, infecções agudas, ferimentos, doenças de pele e câncer.</p>
                    </div>
                    </div>
 
                </div>
        </div>

    </main>
</template>
<script>
        import {
             VsaList,
             VsaItem,
             VsaHeading,
             VsaContent
        } from 'vue-simple-accordion';
export default {

        name: "TantricaIndividualMulher",
                components: {
              VsaList,
              VsaItem,
              VsaHeading,
              VsaContent
            },
}
</script>
<style scoped>
    .beneficios p{
        display: flex;
        align-items: baseline;
    }
    .beneficios img{
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
    .vsa-item{
        border: 1px solid #fff;
        padding: 10px;
        margin-bottom: 10px;
        position: relative;
        color: #fff;
        transition: all 1.5s ease-out;

    }
    .vsa-item h3{
        width: 95%;
        font-size: 16px;
        text-align: left;
            cursor: pointer;
    }
    .vsa-item h3::after{
        content: '+';
        font-size: 38px;
        margin-left: 15px;
        position: absolute;
        right: 10px;
        top: 0px;
    }    
    .vsa-item--is-active  h3::after{
        content: '-';
    }
    .flex{
        background-color: rgba(38, 65, 52, .6);;
        padding: 10px;
        color: #fff;
}   
    a{
        color: #fff;
        background-color: #264134 ;
        padding: 5px;
        border-radius: 5px;
    }
    a:hover{
        color: rgb(155, 113, 56)
    }

    .conteudo{
        background-color: rgba(155, 113, 56, .8);
        padding: 10px;
        color: #Fff;
        text-align: left;
    }
    h4{
        color: #fff;
        font-size: 18px;
        text-align: center;
    }
    p{
          text-align: justify;
    }
    .image{
        background-image:url('../assets/imagens_tantrica mulher.jpg') ;
        background-repeat: no-repeat;
        background-size:cover ;
        height: 290px;
        display: flex;
                justify-content: flex-end;
        align-items: center;
    }
    .text-image{
  margin: auto;
    text-align: justify;
    color: #fff;
    padding: 20px 20px;
    width: auto;
    background-color: rgba(38, 65, 52, .8);
    margin: 0;
    }

    @media (min-width: 700px){
    .vsa-item h3{
        width: auto;
        font-size: 24px;

    }
    .beneficios{
        display: flex;
    }
      .beneficios .col{
        width: 50%;
    }
    .vsa-item h3::after{
        top: -5px;
    }    
        .image{
            height: 800px;
            justify-content: flex-end;
            align-items: center;
        }
        .conteudo-corrente{
            padding: 40px;
        }
        .flex{
            padding: 40px;
            
        }
        .col{
            padding: 10px;
        }

        .text-image{
          padding: 50px 20px;
          width: auto;
          background-color: rgba(38, 65, 52, .8);
          margin: 0;
        }
        .text-image h3{
            font-size: 72px;
        }
        h4{
            font-size: 38px;
        }
     }
</style>