<template>
    <main>
        <div class="page-conteudo">
            <div class="container">
                <div class="conteudo">
                    <h3>Missão</h3>
                    <p>Proporcionar um atendimento de excelência com das melhores técnicas terapêuticas para assim, promover qualidade de vida, favorecer o resgate da autoestima com terapias capazes de estimular o bem-estar e o relaxamento através de um tratamento humanizado, com profissionais qualificados em um ambiente agradável, relaxante e seguro.</p>
                    <h3>Valores</h3>
                    <p>•	Ética – Atuar com consciência e responsabilidade;</p>
                    <p>•	Humanização - Oferecer um atendimento humanizado que valorize a pessoa;</p>
                    <p>•	Excelência - Superar as expectativas do cliente suprindo suas necessidades no resultado e satisfação;</p>
                    <p>•	Respeito ao colaborador - Estimular aprimoramento, capacitação e conhecimento profissional respeitando o trabalho em equipe;</p>
                    <p>•	Confiabilidade - Construir relacionamentos abertos, e de transparência;</p>
                    <p>•	Respeito ao cliente: tratar, cuidar, e respeitar cada cliente dentro de suas necessidades individuais e exclusividade desejada;</p>
                    <p>•	Comprometimento profissional: atuar de maneira responsável e apoiada em conhecimentos técnico-científicos mantendo-se em constante aperfeiçoamento;</p>
                </div>
            </div>
        </div>

    </main>
</template>
<script>
export default {
    name: "Missao"
}
</script>
<style scoped>
    .page-conteudo{
        padding: 130px 0 150px;
        color: #FFF;
    }
    h3{
        color:#FFF;
        text-align: center;
        padding: 20px 0;
        font-weight: 600;
        font-size: 22px;
    }
    p{
        text-align: justify;
    }
     @media (min-width: 700px){
         h3{
             font-size: 38px;
         }
     }

</style>