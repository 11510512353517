<template>
        <main>
        <div class="page-conteudo">
            <div class="container">
                <div class="image">
                    <div class="text-image">
                         <h2>MASSAGEM TÂNTRICA<br/> PARA CASAIS</h2>
                    </div>
                </div>
                <div class="conteudo">
                        <div class="conteudo-corrente">
                    <h4>MASSAGEM TÂNTRICA PARA CASAIS<br/>
                        Desenvolvimento para Casais na Massagem Tântrica</h4>
                    <h4>A MASSAGEM TÂNTRICA</h4>
                    <p>“Podemos considerar uma das ferramentas presentes na terapêutica tântrica como um portal para conexão com o corpo e sensações. A Massagem Tântrica para homens é uma experiência de autoconhecimento ampla e expansão sensorial que pretende acompanhar um desenvolvimento de totalidade”</p>
                    <p>“Viva essa experiência. Aprecie esse estilo de vida. Seja bem-vindo ao espaço Danrê”</p>
                    <h4>FILOSOFIA DANRÊ</h4>
                    <img src="../assets/FILOSOFIA-DANRE.png" alt="Filosofia Danrê">
                    <h4>METODOLOGIA DANRÊ</h4>
                    <p class="color-green"><strong>o que vai acontecer na massagem?</strong></p>
                    <p>Desenvolvemos uma metodologia divida em quatro partes, onde:</p>
                    <p><strong> 1° etapa: Talk – Diálogo</strong></p>
                    <p>Aqui os nossos terapeutas conduzirão uma breve conversa para entender as demandas do casal.
Como estarão em um ambiente seguro e com total discrição vocês poderão se sentir à vontade para se expressarem verbalmente, falando sobre o corpo de vocês, suas carências, algum distúrbio (se houver), alguma curiosidade sobre o tantra, qual o motivo da terapia, tirar qualquer dúvida ou, até mesmo, não falarem nada. 
</p>
                    <p>Esse tipo de abordagem é a conduta ideal para que possamos proporcionar uma terapia personalizada.
Esse é o momento do casal, vocês decidem como querem iniciar a terapia e qual técnica utilizar.
Os terapeutas irão passar técnicas de respiração, movimentação, toque e conexão para que assim, consigamos dar mais eficácia à terapia.
</p>
                    <p><strong>2º etapa: Deep Release + SensitiveTouch  | Soltura Profunda +Toque Sensorial</strong> </p>
                    <p>Iniciamos a sessão com técnicas de soltura muscular profunda nos dois, para liberar o corpo de dores, cansaço e estresse muscular. Com toque terra (presença) liberamos a musculatura rígida que possa estar inibindo e armazenando energia condensada. É fundamental o relaxamento e liberação muscular para os próximos processos. O corpo livre e aberto sente. O corpo rígido congela e tensiona. Esse é um toque fundamental para a expansão orgástica na sessão. É um bom momento para começar a se conectar com o parceiro(a).</p>
                    <p>Nosso corpo possui uma corrente elétrica capaz de acender lâmpadas. A maioria de nós não dá muita importância a esse fenômeno mas, é importante considerar que a circulação dessa eletricidade é a grande responsável pelo equilíbrio físico, mental e emocional do nosso organismo. O foco desta técnica é proporcionar ao casal uma maior consciência de seus corpos, reconhecimento do potencial orgástico que ele carrega, permitir a conscientização e a experimentação de orgasmos múltiplos, desenvolvendo outras possibilidades de prazer sobre uma nova perspectiva: oferecer um novo caminho de crescimento, expansão e consciência.</p>
                    <p>A bioeletricidade corporal é despertada através deste processo passando da 2ª para a 1ª camada da pele, retirando a ideia e o foco de orgasmo apenas pelos genitais movimentando e despertando pontos sensíveis e adormecidos de energia espalhando-se para o corpo todo como fonte de prazer e energia inesgotável.</p>
                    <p><strong> 3° etapa: Lingam Massagem &#38; Yoni Massagem</strong> </p>
                    <p>Lingam é uma palavra do sânscrito que designa o órgão sexual masculino: na tradução literal significa “bastão de luz”. No Tantra o Lingam é respeitosamente visto e honrado, como o “bastão de luz” que canaliza energia criativa e prazer. Já Yoni é uma palavra do Sânscrito (योिन) que significa “passagem divina”, “lugar de nascimento”, “fonte de vida”, “templo sagrado” e ainda o órgão sexual feminino. É considerado igualmente um símbolo de Shakti e de outras deusas de natureza similar.</p>
                    <p>A Lingam Massagem é feita com toques e manobras muito específicas e elaboradas não para masturbar o pênis, mas para estimular, que são realizados alternadamente no Lingam (pênis), nos testículos e na região do períneo.  São utilizados vários tipos de toques e alongamentos, visando levar maior oxigenação e irrigação sanguínea para toda a área pélvica, ativando pontos inconscientemente, adormecidos proporcionando sensações intensas e, por muitas vezes, desconhecida pelos homens.</p>
                    <p>Na Yoni Massagem, o trabalho é focado, superdimensionado e intensificado na Vagina (Yoni) . É importante frisar que estas manipulações genitais não são movimentos masturbatórios, são técnicas que estimulam a vulva e a vagina que foram profundamente estudados e pesquisados, e são especificamente aplicados com o objetivo de aumentar o aspecto sensorial da região genital, proporcionando um tipo de prazer e estados alterados de percepção e consciência que nunca seriam experimentados com manipulações usuais masturbatórias ou sexuais. Na Yoni Massagem, geralmente as mulheres experimentam orgasmos múltiplos, sem declínio do prazer e uma expansão da energia em direção ao 7º Chakra – Saashara, um centro de energia localizado no topo da cabeça, vinculado ao sagrado, ao aspecto divino que existe dentro de nós. São utilizados vários tipos de toques, visando levar maior oxigenação e irrigação sanguínea para toda a área pélvica, ativando pontos reflexológicos e proporcionando inúmeras sensações que, por vezes, as mulheres nunca sentiram durante toda a sua vida sexual.</p>
                    <p>Nesta técnica Danrê oferecemos a possibilidade da mulher explorar todo o potencial que a área G, também conhecido como Ponto G, pode oferecer em termos de expansão sensorial e um nível máximo de prazer. Nas Mulheres o estímulo é produzido após uma sucessão de manobras específicas na região vagina (parte interna) e vulva (parte externa que compreende omonte de vênus, o clitóris e os grandes e pequenos lábios), em contato direto com essa região.</p>
                    <p>Nossa experiência demonstra que o trabalho possibilita um aumento significativo na intensificação do prazer primeiramente por conectar e devolver a consciência da mulher a esta região que dificilmente é estimulado ou tocada em relações ou masturbações.</p>
                    <p>Estes estímulos, tanto no homem quanto na mulher, potencializam a energia e, cada vez mais, distribuem-na através dos meridianos de força em direção ascendente ao 7º Chakra (Saashara ou Coronário), localizado no topo da cabeça. Quando a energia expande, produz uma série de reflexos neuromusculares, produzindo excitações nos lugares sensíveis, espalhando os estímulos também pelos pontos ainda adormecidos.</p>
                    <p>Sim, homens possuem um ponto G, mas nesse caso chama-se ponto P, de próstata.</p>
                    <p>De fato, a maioria dos homens acredita que o pênis é a única zona erógena, mas a verdade é que há outra zona do corpo que, quando estimulada, pode oferecer um prazer inigualável. Essa zona encontra-se entre os testículos e o ânus.</p>
                    <p>Na nossa técnica oferecemos a possibilidade do homem explorar todo o potencial que o ponto P pode proporcionar em termos de expansão sensorial, permissividade e um nível máximo de prazer que poucos experimentaram em toda a sua vida. Nos homens o estímulo é produzido após uma sucessão de manobras específicas na região externa do ânus e no interior do ânus, em contato direto com a próstata.</p>
                    <p>“Existe uma pressão social em cima dos homens a respeito da sua masculinidade que, por muitas vezes, erroneamente, é associada à ereção.  A Massagem tântrica quebra este tabu pois não existe obrigatoriedade alguma em ter e/ou manter ereção durante a sessão. Nossa equipe é treinada e livre de crenças sociais limitantes. Queremos te mostrar a partir da sessão que, você pode sentir prazer sem cobrança alguma, sem julgamento algum. Você pode, deve e tem que sentir apenas o que se permitir sentir, onde esse sentimento/sensação é consequência dos reflexos neuronais distribuídos por todo o corpo transformados em uma onda de prazer.”.</p>
                    <p>“Em geral, por questões sociais e por medo de julgamento, a maçante maioria dos homens sentem-se apreensivos ou envergonhados em relação a este estímulo pois, associam o prazer da estimulação prostática à orientação sexual, o que nada tem a ver. A experiência Danrê demonstra que as manobras em nada interfere nas questões de gênero, que tudo é apenas veículo para levar e elevar o prazer individual.”</p>
                    <p>E sim, existem mulheres que não conhecem a sua anatomia e, tão pouco sabem como se satisfazer ou ensinar ao parceiro como satisfazê-la. As sessões são uma ótima oportunidade para se conhecer, se ver de uma forma mais real, se conectar com o seu eu interior e, principalmente, se amar.</p>
                    <p>Todas essas sensações acontecendo ao mesmo tempo com o casal vão estreitando a conexão através de outros sentidos como o olfato, a audição, o tato. Tudo isso serve para que o casal se reconecte, se veja por outra perspectiva, se amem e dissolvam qualquer tabu entre eles.</p>
                    <p><strong>O seu corpo fala com você! Você sabia?</strong></p>
                    <p>Vivemos em um tempo de muitos estímulos, obrigações e da valorização da racionalidade. Social, pessoal e profissionalmente, sofremos muitas cobranças e exigências para altas performances em quase todos os setores da vida.</p>
                    <p>Relacionamentos, de uma forma geral, envolvem observação, cuidado, paciência e quase sempre são baseados em emoções e afeto.</p>
                    <p>Sentir o que é verdadeiramente importante emocionalmente pra o outro faz toda a diferença na qualidade e na permanência das relações amorosas.</p>
                    <p>Na atualidade, vários casais têm dificuldades, limitações e condicionamentos que não lhes permitem alcançar o prazer, a intimidade e o orgasmo em sua plenitude.</p>
                    <p>Na terapia tântrica, “fazemos as pazes”, perdoamos, nos livramos da culpa e da vergonha e nos entregamos às sensações do corpo. O casal desenvolve e elabora a percepção do prazer de estar em contato com outro, com seu corpo e com a expansão da potencialidade energética que isto proporciona a ambos.</p>
                    <p>Medos e inseguranças, timidez excessiva, vergonha, culpa, recriminação ou rotina, podem comprometer um relacionamento. Some-se a isso o desconhecimento do corpo, da sua fisiologia e de suas reações. Por vezes, ambos sofrem de disfunções sexuais, como anorgasmia (falta de orgasmos), problemas com a libido, baixa autoestima, ejaculação precoce, impotência, vaginismo ou dores sexuais (dispareunia). Essas dificuldades atrapalham ou impedem um relacionamento amoroso e sexual harmonioso.</p>
                    <p>Os tratamentos convencionais são limitados, muito lentos e quase sempre de difícil superação. Muitos desses problemas afetam a confiança, comprometendo a entrega ao prazer e ao orgasmo, o que, por sua fez, afeta a qualidade de vida diretamente.</p>
                    <p>Através da terapêutica tântrica é trabalhada a arte do toque e o uso dos sentidos como instrumento para alcançar maior percepção e sensibilidade, estreitando assim os vínculos de companheirismo, de amor, e de amizade no relacionamento. Desenvolve também sua capacidade sensorial e orgástica, através da continuidade das sessões, pela tonificação dos músculos sexuais, e expande a tantra toque capacidade de experimentar e sustentar níveis mais elevados de prazer. É preciso, em primeiro lugar, conhecer os princípios masculino e feminino que modelam grande parte dos comportamentos no dia adia. Quando a pessoa reprime sua sexualidade, o resultado é um desequilíbrio enorme no seu comportamento, na sua vitalidade e nas suas emoções.</p>
                    <p>O tantra é o conhecimento mais profundo que existe sobre a energia sexual, a energia vital. Seu uso correto é responsável pela beleza, juventude e atração entre os sexos. Somente o tantra, através dos milênios, tem se atrevido a ensinar isso com a simplicidade, com a pureza e com a profundidade que os ensinamentos precisam ser passados.</p>
         
                    <h4>QUAIS OS BENEFÍCIOS?</h4>
      
                    <div class="beneficios">
                            <div class="col">
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">	Tratamento de distúrbios sexuais (ejaculação precoce, ejaculação tardia e anorgasmia)</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">	Tratamento de distúrbios psicossomáticos</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">	Trabalho e tratamento de traumas</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Toque consciente</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Reconhecer os diversos pontos de prazer através dos sentidos;</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Reconhecer e controlar os estados de excitação, a fim de ajustar-se às experiências ou ao ritmo do(a) parceiro(a);</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Potencializar o condicionamento dos músculos ejaculadores, dos músculos intravaginais e clitorianos, preparando-os para maior volume de orgasmos e orgasmos múltiplos para homens e mulheres;</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Melhora a qualidades dos orgasmos</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Melhora a qualidade do sono</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Melhora a performance sexual</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Liberação dos bloqueios energéticos sexuais</p>
                            </div>
                            <div class="col">
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Despertamento espiritual</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Desperta regiões sensoriais adormecidas</p> 
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Conhecer aspectos da anatomia, a sua e a do (a) parceiro(a);</p>                       
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Conectar a voz, a expressão, a comunicação e a respiração consciente às sensações que experimenta;</p>  
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Causa sensação de bem-estar profundo</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Autoconfiança</p>    
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Aumento da libido e orgasmos múltiplos</p>    
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Aumentar a intimidade, a entrega e a confiança, resgatando e/ou melhorando a relação.</p> 
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Alívio de estresse</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Alívio de dores</p>
                                <p><img src='../assets/bolinhas-txt.png' alt="bolinha">Ajuda na melhora de bloqueios emocionais</p>
                        
                            </div>
                        </div>
                    </div>



                </div>
            <div class="flex">
                        <div class="col">
                            <h4>INVESTIMENTO</h4>
                            <p>R$ 450,00 – Sessão única (dinheiro, pix credito ou débito)</p>
                        </div>
                        <div class="col">
        <h4>DÚVIDAS FREQUENTES</h4>
                <vsa-list>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Qual a Segurança com a Higiene e Saúde?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Todos os terapeutas cadastrados no Paraíso Tantra seguem protocolos de biossegurança, saúde e higiene rigorosos para os atendimentos. A utilização de luvas nitrílicas ou látex para os processos de massagens íntimas e internas fazem parte deste protocolo. Caso você tenha hipersensibilidade ao látex temos um outro material disponível. Como padrão em nossas unidades todos os nossos lençóis são descartáveis após o atendimento. A limpeza e esterilização de materiais para o procedimento é feito conforme as recomendações da ANVISA (agência nacional de vigilância sanitária) aos quais somos certificados.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Quais são os Produtos Utilizados?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Materiais de origem vegetal para o corpo e genitais. Óleo de semente de Uva ou Óleo de Coco, famosos por terem propriedades medicinais e não representarem qualquer dano à saúde.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Como o terapeuta fica vestido?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Para a massagem tântrica não há necessidade de o terapeuta despir-se. O terapeuta facilita o seu processo na condução e movimentação da energia sexual. Sendo um condutor e facilitador do seu aprendizado o terapeuta utiliza roupas leves, confortáveis, que facilitem sua movimentação durante o processo da massagem.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Como eu fico vestido?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>O cliente fica com suas roupas íntimas coberto por uma toalha,  a única parte que fica exposta é a parte que vai ser tratada.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Existe Masturbação?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Não, você não será masturbado(a). Os processos de massagem lingam e yoni massagem são cientificamente estudados e exigem um conhecimento técnico específico para serem realizados, são manobras e movimentos alternados que possibilitam um conhecimento amplo da região. Todos os nossos profissionais estão capacitados para realizá-los. Então, fique tranquilo (a). A masturbação é um procedimento automático com movimentos repetitivos e pobres que fazem alcançar um “prazer” imediato através da pressão e manipulação impossibilitando totalmente o conhecimento corporal intenso e o orgasmo terapêutico.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Como posso “ejacular” sem ter sexo, penetração ou interação?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Esse é o ponto. Se você é uma pessoa que não imagina como poderia chegar a um êxtase total sem sexo, interação ou qualquer tipo de contato direto com uma outra pessoa, você realmente precisa conhecer o Tantra e a sua capacidade orgástica. A ejaculação é um processo distinto do orgasmo. (leia mais em nosso blog)</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Posso tocar o terapeuta?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>O Toque é um dos sentidos mais apurados dentro da nossa cultura e que praticamos involuntariamente. Vamos as compras ou ao supermercado e olhamos um produto, uma roupa, na prateleira. Pedimos ao vendedor: -“Posso ver?” e ele coloca o produto em cima de suas mãos. Se não pudermos tocar, virar, sentir a textura do tecido nós realmente não o enxergamos. Estamos o tempo todo utilizando os nossos sentidos, mesmo sem ter os percebidos realmente. O toque como um formato de sentir-se seguro na sessão, apoiar-se no outro é bem-vindo, o que não pode acontecer é uma vulgarização através do toque acreditando ser um processo de troca ou mais uma distração para ir para fora. A experiência é meditativa e a proposta é a conexão consigo. Incentivamos o auto toque para conexão!</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Existe algum contato corpo a corpo?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Não. Existem algumas informações e retratações infiéis a massagem tântrica espalhadas por aí. Em algumas delas é visto uma pessoa esfregando o corpo na outra, deslizando com óleos, gel, creme. Nada disso é Tantra, aliás isso não irá lhe trazer nenhum beneficio de conhecimento corporal. Nossos terapeutas estão inteiramente conectados com a proposta de trazer maior expansão e conexão orgástica além de trabalhar o desenvolvimento para auxílio as disfunções sexuais.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Posso ser atendido por um terapeuta do mesmo sexo?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Sim. Os terapeutas são condutores da capacidade orgástica e energia sexual. Se uma mulher receber a sessão de outra mulher ela não se torna homossexual por isso. Inclusive algumas mulheres sentem-se mais confortáveis com uma terapeuta, logo a questão da polaridade (homem/mulher) pode ser descartada como regra. Você pode fazer a sessão com quem se sentir mais confortável.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Qual o tempo de duração da sessão?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Em média 60 minutos (1h). Recomendamos que deixe um espaço de tempo de 1h e 40 minutos para realizar a sessão. Não se preocupe com o seu compromisso posterior durante o processo. É o seu momento, nada pode te atrapalhar.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>Existe sexo durante este processo?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Não. Sexo de qualquer espécie, complementos sexuais ou afins estão completamente descartados das nossas sessões.</p>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item>
                        <vsa-heading>
                         <h3>É possível realizar a sessão em menor tempo?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Não, a sessão de Massagem Tântrica é detalhada, expansiva e é necessário o período estipulado.</p>
                        </vsa-content>
                    </vsa-item>
                                     <vsa-item>
                        <vsa-heading>
                         <h3>Posso escolher a terapeuta?</h3>
                        </vsa-heading>
                        <vsa-content>
                            <p>Claro. Fique a vontade, todos os nossos terapeutas estão capacitados para o atendimento.</p>
                        </vsa-content>
                    </vsa-item>
                </vsa-list>
                        </div>
                                 <div class="col">
                    <h4>CONTRA INDICAÇÕES NA MASSAGEM!</h4>
                    <p>Todas as terapias possuem suas indicações e contraindicações. </p>
                    <p>Nossas massagens são contraindicadas para indivíduos com:</p>
                    <p>Contusões, pele inflamada, ferimentos, tumores, hérnia abdominal e áreas com fraturas recentes;</p>
                    <p>Se você estiver com alguma condição dérmica infecciosas, rash ou feridas abertas;</p>
                    <p>Após cirurgias recentes;</p>
                    <p>Logo após sessões de quimioterapia ou radioterapia, a menos que seja recomendado por seu médico;</p>
                    <p>Propensão a coágulos sanguíneos. Existe o risco dos coágulos se soltarem. Se você tem algum tipo de doença cardíaca verifique com seu médico se há alguma restrição para este tipo de massagem;</p>
                    <p>Mulheres grávidas devem sempre consultar o médico antes de receber massagem.</p>
                    <p>Asma aguda, cistite aguda, infecções agudas, ferimentos, doenças de pele e câncer.</p>
                    </div>
                    </div>
            </div>
        </div>
                    
    </main>
</template>
<script>
        import {
             VsaList,
             VsaItem,
             VsaHeading,
             VsaContent
        } from 'vue-simple-accordion';
export default {
        name: "TantricaCasal",
                        components: {
              VsaList,
              VsaItem,
              VsaHeading,
              VsaContent
            },
}
</script>
<style scoped>
    .beneficios p{
        display: flex;
        align-items: baseline;
    }
    .beneficios img{
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
    .vsa-item{
        border: 1px solid #fff;
        padding: 10px;
        margin-bottom: 10px;
        position: relative;
        color: #fff;
        transition: all 1.5s ease-out;

    }
    .vsa-item h3{
        width: 95%;
        font-size: 16px;
        text-align: left;
        cursor: pointer;
    }
    .vsa-item h3::after{
        content: '+';
        font-size: 38px;
        margin-left: 15px;
        position: absolute;
        right: 10px;
        top: 0px;
    }    
    .vsa-item--is-active  h3::after{
        content: '-';
    }
    .flex{
        background-color: rgba(38, 65, 52, .6);;
        padding: 10px;
        color: #fff;
}   
    a{
        color: #fff;
        background-color: #264134 ;
        padding: 5px;
        border-radius: 5px;
    }
    a:hover{
        color: rgb(155, 113, 56)
    }
    .page-conteudo{
        padding: 130px 0 150px;
    }
    .conteudo{
        background-color: rgba(155, 113, 56, .8);
        padding: 10px;
        color: #Fff;
        text-align: left;
    }
    h4{
        color: #fff;
        font-size: 18px;
        text-align: center;
    }
    p{
          text-align: justify;
    }
    .image{
        background-image:url('../assets/imagens_casal.jpg') ;
        background-repeat: no-repeat;
        background-size:cover ;
        height: 290px;
        display: flex;
                justify-content: flex-end;
        align-items: center;
    }
    .text-image{
    margin: auto;
    text-align: justify;
    color: #fff;
    padding: 20px 20px;
    width: auto;
    background-color: rgba(38, 65, 52, .8);
    margin: 0;
    }

    @media (min-width: 700px){
    .vsa-item h3{
        width: auto;
        font-size: 24px;

    }
    .beneficios{
        display: flex;
    }
      .beneficios .col{
        width: 50%;
    }
    .vsa-item h3::after{
        top: -5px;
    }    
        .image{
            height: 800px;
            justify-content: flex-end;
            align-items: center;
        }
        .conteudo-corrente{
            padding: 40px;
        }
        .flex{
            padding: 40px;
            
        }
        .col{
            padding: 10px;
        }

        .text-image{
          padding: 50px 20px;
          width: auto;
          background-color: rgba(38, 65, 52, .8);
          margin: 0;
        }
        .text-image h3{
            font-size: 72px;
        }
        h4{
            font-size: 38px;
        }
     }
</style>